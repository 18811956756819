import { DeviceResolver } from '../../types';
import { DeviceType } from '../../../../generated/graphql';
import AbstractLocalDevicesResolver from '../../shared/AbstractLocalDevicesResolver';
import { DeviceResolverParamsType } from '../../shared/types';

export default class WindowsMockedResolver extends AbstractLocalDevicesResolver {
  public async resolve(
    params: DeviceResolverParamsType
  ): Promise<DeviceResolver[]> {
    const devices: DeviceResolver[] = [
      {
        deviceId: 'windows-pc-local-1',
        lastUpdatedAt: '2020-01-01T00:00:00.000Z',
        type: DeviceType.Pc,
        identity: {
          bizModel: 'bizModelWindowsPCLocal1',
          deviceSegment: 'deviceSegmentWindowsPCLocal1',
          deviceUuid: 'deviceUuidWindowsPCLocal1',
          friendlyName: 'friendlyNameWindowsPCLocal1',
          lastUpdatedAt: '2020-01-01T00:00:00.000Z',
          location: 'locationWindowsPCLocal1',
          serialNumber: 'windows-pc-local-1-serial-number',
          makeAndModel: {
            name: 'nameWindowsPCLocal1',
            number: 'numberWindowsPCLocal1',
            series: 'seriesWindowsPCLocal1',
            __typename: 'DeviceMakeAndModel'
          },
          __typename: 'DeviceIdentity'
        },
        deviceOwnership: null,
        images: [{ url: 'url1-WindowsPCLocal1', __typename: 'DeviceImage' }],
        __typename: 'PCDevice'
      },
      {
        deviceId: 'windows-pc-local-2',
        lastUpdatedAt: '2020-01-01T00:00:00.000Z',
        type: DeviceType.Printer,
        identity: {
          // This is the only difference between this file and WindowsMockedResolver2.ts
          bizModel: 'bizModelWindowsPCLocal-NEW-2',
          __typename: 'DeviceIdentity'
        },
        __typename: 'PrinterDevice'
      }
    ];

    // The normalize method is responsible to remove duplicates and normalize the data
    super.normalize(devices, params);

    // Don't change the return value of this method
    return super.resolve(params);
  }
}
