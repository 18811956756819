export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
};

export type AccessoryDevice = Device & {
  __typename?: 'AccessoryDevice';
  deviceId?: Maybe<Scalars['String']['output']>;
  deviceOwnership?: Maybe<Account>;
  grants?: Maybe<Grants>;
  identity?: Maybe<DeviceIdentity>;
  images?: Maybe<Array<Maybe<DeviceImage>>>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  offerings?: Maybe<Array<Maybe<Offering>>>;
  support?: Maybe<Support>;
  type?: Maybe<DeviceType>;
};

export type Account = {
  __typename?: 'Account';
  addresses?: Maybe<Array<Maybe<Address>>>;
  grants?: Maybe<Grants>;
  offerings?: Maybe<Array<Maybe<Offering>>>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
  resourceId: Scalars['String']['output'];
  subscriptions?: Maybe<Array<Maybe<HpSubscription>>>;
  user?: Maybe<User>;
  /** List of users from account */
  users?: Maybe<UserItems>;
};


export type AccountSubscriptionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  subscriptionFilter?: InputMaybe<SubscriptionFilter>;
};


export type AccountUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Union type for different types of actions */
export type Action = LinkAction;

export type Address = {
  __typename?: 'Address';
  address: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  addressType: AddressType;
  city: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  resourceId: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export enum AddressType {
  Billing = 'BILLING',
  Shipping = 'SHIPPING'
}

export type BatteryStatus = {
  __typename?: 'BatteryStatus';
  /** Whether the battery is currently charging or not */
  isCharging: Scalars['Boolean']['output'];
  /** The current level of the battery in percentage (0-100) */
  level: Scalars['Int']['output'];
};

export type BillingCycle = {
  __typename?: 'BillingCycle';
  chargeDate?: Maybe<Scalars['String']['output']>;
  commonBillingDate?: Maybe<Scalars['String']['output']>;
};

export type CarePackEntity = SubscriptionEntity & {
  __typename?: 'CarePackEntity';
  fulfillments?: Maybe<Array<Maybe<Fulfillment>>>;
  product?: Maybe<Product>;
  resourceId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<EntityType>;
};

export type CitizensPay = PaymentMethod & {
  __typename?: 'CitizensPay';
  offerCategory?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PaymentMethodStatus>;
  type: PaymentMethodType;
};

/** Enumerates the possible connection states for a device */
export enum ConnectionState {
  /** The device is currently offline and not connected */
  Offline = 'OFFLINE',
  /** The device is currently online and connected */
  Online = 'ONLINE'
}

export type CreditCard = PaymentMethod & {
  __typename?: 'CreditCard';
  cardType?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['String']['output']>;
  lastFour?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PaymentMethodStatus>;
  type: PaymentMethodType;
};

export type Device = {
  deviceId?: Maybe<Scalars['String']['output']>;
  deviceOwnership?: Maybe<Account>;
  grants?: Maybe<Grants>;
  identity?: Maybe<DeviceIdentity>;
  images?: Maybe<Array<Maybe<DeviceImage>>>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  offerings?: Maybe<Array<Maybe<Offering>>>;
  support?: Maybe<Support>;
  type?: Maybe<DeviceType>;
};

/** DeviceEntity includes: IOT_PC, IOT_PRINTER, MOUSE, KEYBOARD, PEN */
export type DeviceEntity = SubscriptionEntity & {
  __typename?: 'DeviceEntity';
  fulfillments?: Maybe<Array<Maybe<Fulfillment>>>;
  product?: Maybe<Product>;
  resourceId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<EntityType>;
};

export type DeviceIdentity = {
  __typename?: 'DeviceIdentity';
  bizModel?: Maybe<Scalars['String']['output']>;
  deviceSegment?: Maybe<Scalars['String']['output']>;
  deviceUuid?: Maybe<Scalars['String']['output']>;
  firmwareVersion?: Maybe<Scalars['String']['output']>;
  friendlyName?: Maybe<Scalars['String']['output']>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  makeAndModel?: Maybe<DeviceMakeAndModel>;
  programLevel?: Maybe<ProgramLevel>;
  serialNumber?: Maybe<Scalars['String']['output']>;
};

export type DeviceImage = {
  __typename?: 'DeviceImage';
  orientation?: Maybe<ImageType>;
  url?: Maybe<Scalars['String']['output']>;
};

export type DeviceItems = {
  __typename?: 'DeviceItems';
  items?: Maybe<Array<Maybe<Device>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DeviceMakeAndModel = {
  __typename?: 'DeviceMakeAndModel';
  family?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  series?: Maybe<Scalars['String']['output']>;
};

export enum DeviceType {
  Keyboard = 'KEYBOARD',
  Mouse = 'MOUSE',
  Pc = 'PC',
  Pen = 'PEN',
  Printer = 'PRINTER',
  Unknown = 'UNKNOWN'
}

export type DirectDebit = PaymentMethod & {
  __typename?: 'DirectDebit';
  accountName?: Maybe<Scalars['String']['output']>;
  bankMandate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PaymentMethodStatus>;
  type: PaymentMethodType;
};

export enum EntityType {
  Carepack = 'CAREPACK',
  Instantpaper = 'INSTANTPAPER',
  InstantInk = 'INSTANT_INK',
  IotPc = 'IOT_PC',
  IotPrinter = 'IOT_PRINTER',
  Keyboard = 'KEYBOARD',
  Mouse = 'MOUSE',
  Payment = 'PAYMENT',
  Pen = 'PEN',
  ThirdPartySoftware = 'THIRD_PARTY_SOFTWARE'
}

export type Fulfillment = {
  status?: Maybe<FulfillmentStatus>;
};

export type FulfillmentBundle = Fulfillment & {
  __typename?: 'FulfillmentBundle';
  parts?: Maybe<Array<Maybe<FulfillmentBundlePart>>>;
  status?: Maybe<FulfillmentStatus>;
};

export type FulfillmentBundlePart = {
  __typename?: 'FulfillmentBundlePart';
  carrier?: Maybe<Scalars['String']['output']>;
  estimatedDeliveryDate?: Maybe<Scalars['String']['output']>;
  shipmentDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<FulfillmentStatus>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

export type FulfillmentSingle = Fulfillment & {
  __typename?: 'FulfillmentSingle';
  carrier?: Maybe<Scalars['String']['output']>;
  estimatedDeliveryDate?: Maybe<Scalars['String']['output']>;
  shipmentDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<FulfillmentStatus>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

export enum FulfillmentStatus {
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Delivered = 'DELIVERED',
  Error = 'ERROR',
  Invoiced = 'INVOICED',
  Lostintransit = 'LOSTINTRANSIT',
  New = 'NEW',
  Onhold = 'ONHOLD',
  Processing = 'PROCESSING',
  Received = 'RECEIVED',
  Releasehold = 'RELEASEHOLD',
  Shipped = 'SHIPPED',
  Undelivered = 'UNDELIVERED',
  Unknown = 'UNKNOWN'
}

export type Grant = {
  __typename?: 'Grant';
  grant?: Maybe<Scalars['String']['output']>;
};

/**
 * Grants type includes lists of active and peding grants, and grants history.
 * The Grants type is used to represent the grants of an account or device.
 */
export type Grants = {
  __typename?: 'Grants';
  /** List of active grants */
  active?: Maybe<Array<Maybe<Grant>>>;
  /** List of grants history */
  history?: Maybe<Array<Maybe<Grant>>>;
  /** List of pending grants */
  pending?: Maybe<Array<Maybe<Grant>>>;
};

export type HpSubscription = {
  __typename?: 'HpSubscription';
  billing?: Maybe<SubscriptionBilling>;
  devices?: Maybe<DeviceItems>;
  entities?: Maybe<Array<Maybe<SubscriptionEntity>>>;
  friendlyId?: Maybe<Scalars['String']['output']>;
  price?: Maybe<SubscriptionPrice>;
  resourceId: Scalars['String']['output'];
  shippingAddress?: Maybe<Address>;
  state?: Maybe<SubscriptionState>;
  type?: Maybe<SubscriptionType>;
};

/** Icon resource type */
export type IconResource = {
  __typename?: 'IconResource';
  /** Type of the icon resource */
  type: IconResourceType;
  /** Value of the icon resource */
  value: Scalars['String']['output'];
};

export enum IconResourceType {
  /** Resource value is a key for localization */
  Key = 'KEY',
  /** Resource value is a URL */
  Url = 'URL'
}

/** Enum for image type */
export enum ImageType {
  Centerfacing = 'CENTERFACING',
  Leftfacing = 'LEFTFACING',
  Rightfacing = 'RIGHTFACING',
  Unknown = 'UNKNOWN'
}

export type InstantInkEntity = SubscriptionEntity & {
  __typename?: 'InstantInkEntity';
  fulfillments?: Maybe<Array<Maybe<Fulfillment>>>;
  product?: Maybe<Product>;
  resourceId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<EntityType>;
};

export type InstantPaperEntity = SubscriptionEntity & {
  __typename?: 'InstantPaperEntity';
  fulfillments?: Maybe<Array<Maybe<Fulfillment>>>;
  priceCents?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<Product>;
  resourceId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<EntityType>;
};

/** Type for link action */
export type LinkAction = {
  __typename?: 'LinkAction';
  /** Link associated with the action */
  link: LinkResource;
};

/** Link resource type */
export type LinkResource = {
  __typename?: 'LinkResource';
  /** Type of link resource */
  type: LinkResourceType;
  /** URL of the link resource */
  url: Scalars['String']['output'];
};

/** Enum for link resource types */
export enum LinkResourceType {
  /** an external link that is expected to be handled outside of the current application */
  External = 'EXTERNAL',
  /** An internal link that is expected to be handled within the current application */
  Internal = 'INTERNAL'
}

/** Type for offerings associated with a device or account */
export type Offering = {
  __typename?: 'Offering';
  /** The ID of an offering */
  serviceId?: Maybe<Scalars['String']['output']>;
};

export type PcDevice = Device & {
  __typename?: 'PCDevice';
  deviceId?: Maybe<Scalars['String']['output']>;
  deviceOwnership?: Maybe<Account>;
  grants?: Maybe<Grants>;
  identity?: Maybe<DeviceIdentity>;
  images?: Maybe<Array<Maybe<DeviceImage>>>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  offerings?: Maybe<Array<Maybe<Offering>>>;
  /** Details about the status of the PC */
  status?: Maybe<PcStatus>;
  support?: Maybe<Support>;
  type?: Maybe<DeviceType>;
};

/** Type representing the status of a PC */
export type PcStatus = {
  __typename?: 'PCStatus';
  /** The battery status of the PC */
  battery?: Maybe<BatteryStatus>;
};

export type PayPal = PaymentMethod & {
  __typename?: 'PayPal';
  email?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PaymentMethodStatus>;
  type: PaymentMethodType;
};

export type PaymentEntity = SubscriptionEntity & {
  __typename?: 'PaymentEntity';
  fulfillments?: Maybe<Array<Maybe<Fulfillment>>>;
  product?: Maybe<Product>;
  resourceId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<EntityType>;
};

export type PaymentMethod = {
  status?: Maybe<PaymentMethodStatus>;
  type: PaymentMethodType;
};

export enum PaymentMethodStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Disabled = 'DISABLED',
  Inactive = 'INACTIVE'
}

export enum PaymentMethodType {
  CitizensPay = 'CITIZENS_PAY',
  CreditCard = 'CREDIT_CARD',
  DirectDebit = 'DIRECT_DEBIT',
  PayPal = 'PAY_PAL',
  TwoCheckout = 'TWO_CHECKOUT'
}

/** Represents information about the print anywhere solution */
export type PrintAnywhereSolution = {
  __typename?: 'PrintAnywhereSolution';
  /** Indicates whether the Print Anywhere feature is enabled */
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
};

/** Represents the connection status of the printer */
export type PrinterConnectionStatus = {
  __typename?: 'PrinterConnectionStatus';
  /** The current connection status of the printer */
  connectionState: ConnectionState;
};

/** Enum for printer consumable colors */
export enum PrinterConsumableColor {
  Black = 'BLACK',
  Blue = 'BLUE',
  Cmy = 'CMY',
  Cmyk = 'CMYK',
  Cyan = 'CYAN',
  DarkGray = 'DARK_GRAY',
  Fixer = 'FIXER',
  GlossEnhancer = 'GLOSS_ENHANCER',
  Gray = 'GRAY',
  Green = 'GREEN',
  Kcm = 'KCM',
  Km = 'KM',
  LightCyan = 'LIGHT_CYAN',
  LightGray = 'LIGHT_GRAY',
  LightMagenta = 'LIGHT_MAGENTA',
  Magenta = 'MAGENTA',
  MatteBlack = 'MATTE_BLACK',
  Mk = 'MK',
  MultiColor = 'MULTI_COLOR',
  My = 'MY',
  Optimizer = 'OPTIMIZER',
  Orange = 'ORANGE',
  OrganicPhotoConductor = 'ORGANIC_PHOTO_CONDUCTOR',
  Overcoat = 'OVERCOAT',
  Photo = 'PHOTO',
  PhotoBlack = 'PHOTO_BLACK',
  Red = 'RED',
  ScratchAgent = 'SCRATCH_AGENT',
  Violet = 'VIOLET',
  White = 'WHITE',
  Yc = 'YC',
  Yellow = 'YELLOW'
}

export type PrinterDevice = Device & {
  __typename?: 'PrinterDevice';
  deviceId?: Maybe<Scalars['String']['output']>;
  deviceOwnership?: Maybe<Account>;
  grants?: Maybe<Grants>;
  identity?: Maybe<DeviceIdentity>;
  images?: Maybe<Array<Maybe<DeviceImage>>>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  offerings?: Maybe<Array<Maybe<Offering>>>;
  /** Fetches a list of SMS status messages, optionally filtered by id, severity, and limited by a specified amount. The list is ordered by priority (highest priority first). */
  smsStatusMessages?: Maybe<Array<Maybe<SmsStatusMessage>>>;
  /** Information about printer-specific solutions */
  solutions?: Maybe<PrinterSolutions>;
  /** The current status of the printer */
  status?: Maybe<PrinterStatus>;
  support?: Maybe<Support>;
  type?: Maybe<DeviceType>;
};


export type PrinterDeviceSmsStatusMessagesArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  severity?: InputMaybe<SmsStatusMessageSeverity>;
};

/** Represents the onboarding status of the printer */
export type PrinterOnboardingStatus = {
  __typename?: 'PrinterOnboardingStatus';
  /** Indicates whether the printer setup is complete */
  setupComplete?: Maybe<Scalars['Boolean']['output']>;
};

/** Represents information about printer-specific solutions */
export type PrinterSolutions = {
  __typename?: 'PrinterSolutions';
  /** Information about the Print Anywhere solution */
  printAnywhere?: Maybe<PrintAnywhereSolution>;
  /** Information about the Private Pickup solution */
  privatePickup?: Maybe<PrivatePickupSolution>;
};

/** Represents the overall status of the printer, including connection, operation, and onboarding statuses */
export type PrinterStatus = {
  __typename?: 'PrinterStatus';
  /** The status of the printer's connection */
  connection?: Maybe<PrinterConnectionStatus>;
  /** The onboarding status of the printer */
  onboarding?: Maybe<PrinterOnboardingStatus>;
};

/** Represents information about the private pickup solution */
export type PrivatePickupSolution = {
  __typename?: 'PrivatePickupSolution';
  /** The number of jobs pending for private pickup */
  jobCount?: Maybe<Scalars['Int']['output']>;
};

export type Product = {
  __typename?: 'Product';
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  /** The type of the product in Magento. */
  type?: Maybe<ProductType>;
};

export enum ProductType {
  Accessories = 'ACCESSORIES',
  Carepack = 'CAREPACK',
  Chromebook = 'CHROMEBOOK',
  Connectivity = 'CONNECTIVITY',
  Googleone = 'GOOGLEONE',
  Instantink = 'INSTANTINK',
  Instantpaper = 'INSTANTPAPER',
  Keyboard = 'KEYBOARD',
  Leasereceivable = 'LEASERECEIVABLE',
  Mouse = 'MOUSE',
  Ms365 = 'MS365',
  Pc = 'PC',
  Printer = 'PRINTER',
  Transactionfees = 'TRANSACTIONFEES'
}

export enum ProgramLevel {
  HpPlus = 'HP_PLUS',
  Ucde = 'UCDE',
  Wfh = 'WFH'
}

export type Query = {
  __typename?: 'Query';
  /** Account information for given user and tenant defined by the access token */
  account?: Maybe<Account>;
  /**
   * Get anonymous devices by a filter. This list can include devices that doesn't belong to the user.
   * Most of the devices data won't be able to be fetched.
   * It's expected to resolve images, type, device name, number, series and serial number.
   */
  anonymousDevices?: Maybe<DeviceItems>;
  /** Complete list of user's devices */
  devices?: Maybe<DeviceItems>;
  /**
   * Get Virtual Agent URL for the given input. The input is used to fetch the Virtual Agent session.
   * It's expected to resolve an error code, an error message and the Virtual Agent URL.
   */
  vaSessionUrl?: Maybe<VaSession>;
};


export type QueryAnonymousDevicesArgs = {
  client?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  oid?: InputMaybe<Scalars['String']['input']>;
  productNumber?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  useCase?: InputMaybe<Scalars['String']['input']>;
  warrantyLevel?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDevicesArgs = {
  deviceId?: InputMaybe<Scalars['String']['input']>;
  deviceType?: InputMaybe<Array<InputMaybe<DeviceType>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVaSessionUrlArgs = {
  vaSessionInput: VaSessionInput;
};

/** Type for SMS status messages */
export type SmsStatusMessage = {
  __typename?: 'SMSStatusMessage';
  /** List of actions that can be taken in response to the status message */
  actions?: Maybe<Array<SmsStatusMessageAction>>;
  /** List of consumable colors associated with the status message */
  consumables?: Maybe<Array<PrinterConsumableColor>>;
  /** Detailed description of the status message, represented as a StringResource */
  description: StringResource;
  /** Unique ID for the SMS status message, this is an opaque ID and should not to be confused with I/O Ref */
  id: Scalars['String']['output'];
  /** Boolean indicating if the status message is blocking operation of the printer or not */
  isBlocking: Scalars['Boolean']['output'];
  /** Severity level of the status message */
  severity: SmsStatusMessageSeverity;
  /** Title of the status message, represented as a StringResource */
  title: StringResource;
};

/** Type for an action linked to a status message */
export type SmsStatusMessageAction = {
  __typename?: 'SMSStatusMessageAction';
  /** Action to be taken, represented as an Action union type */
  action?: Maybe<Action>;
  /** Boolean indicating if the action is the primary action for the status message */
  isPrimary: Scalars['Boolean']['output'];
  /** Title of the action, represented as a StringResource */
  title: StringResource;
};

/** Enum for SMS message severity levels */
export enum SmsStatusMessageSeverity {
  Error = 'ERROR',
  Information = 'INFORMATION',
  Warning = 'WARNING'
}

/** String resource type */
export type StringResource = {
  __typename?: 'StringResource';
  /** Type of the string resource */
  type: StringResourceType;
  /** Value of the string resource */
  value: Scalars['String']['output'];
};

/** Enum for string resource types */
export enum StringResourceType {
  /** Resource value is a key for localization */
  Key = 'KEY',
  /** Resource value is a raw text string */
  Raw = 'RAW'
}

export type SubscriptionBilling = {
  __typename?: 'SubscriptionBilling';
  nextBillingCycle?: Maybe<BillingCycle>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type SubscriptionEntity = {
  fulfillments?: Maybe<Array<Maybe<Fulfillment>>>;
  product?: Maybe<Product>;
  resourceId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<EntityType>;
};

export type SubscriptionFilter = {
  resourceId?: InputMaybe<Scalars['String']['input']>;
  subscriptionType?: InputMaybe<SubscriptionType>;
};

export type SubscriptionPrice = {
  __typename?: 'SubscriptionPrice';
  currency?: Maybe<Scalars['String']['output']>;
  /** Total price of the subscription in cents. */
  price?: Maybe<Scalars['String']['output']>;
};

export enum SubscriptionState {
  /** The subscription is running, HP is actively billing to the tenant. */
  Active = 'ACTIVE',
  /** The subscription is in an error state. */
  Error = 'ERROR',
  /** The subscription is deactivated, no longer billing to the tenant. */
  Inactive = 'INACTIVE',
  /** The subscription is in the process of being unsubscribed. */
  InitiatedUnsubscribe = 'INITIATED_UNSUBSCRIBE',
  /** The subscripton is obsolete. */
  Obsolete = 'OBSOLETE',
  /** The subscription is in pre-ship or pre-billing state. */
  Pending = 'PENDING',
  /** The subscription is subscribed, but with no pens */
  SubscribedNoPens = 'SUBSCRIBED_NO_PENS',
  /** The subscription is subscribed, but with no printers */
  SubscribedNoPrinter = 'SUBSCRIBED_NO_PRINTER',
  /** The subscription is suspended. Service on-hold for some reason, billing still ongoing. */
  Suspended = 'SUSPENDED',
  /** The subscription is unsubscribed. */
  Unsubscribed = 'UNSUBSCRIBED'
}

export enum SubscriptionType {
  HpAllIn = 'HP_ALL_IN',
  InstantInk = 'INSTANT_INK'
}

export type Support = {
  __typename?: 'Support';
  cases?: Maybe<Array<Maybe<SupportCase>>>;
  /** @deprecated This will be removed in the future. */
  contactOptions?: Maybe<SupportContactOption>;
  resources?: Maybe<Array<Maybe<SupportResource>>>;
  supportCode?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  warranty?: Maybe<SupportWarranty>;
};

export type SupportCase = {
  __typename?: 'SupportCase';
  captionClose?: Maybe<Scalars['String']['output']>;
  captionOpen?: Maybe<Scalars['String']['output']>;
  caseId?: Maybe<Scalars['String']['output']>;
  createdDateTime?: Maybe<Scalars['String']['output']>;
  dateClosed?: Maybe<Scalars['String']['output']>;
  incomingChannel?: Maybe<Scalars['String']['output']>;
  orders?: Maybe<Array<Maybe<SupportCaseOrder>>>;
  problemDescription?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  statusText?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  typeValue?: Maybe<Scalars['Int']['output']>;
};

export type SupportCaseOrder = {
  __typename?: 'SupportCaseOrder';
  flowType?: Maybe<Scalars['String']['output']>;
  history?: Maybe<Array<Maybe<SupportCaseOrderHistory>>>;
  orderEntryDate?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
};

export type SupportCaseOrderHistory = {
  __typename?: 'SupportCaseOrderHistory';
  caption?: Maybe<Scalars['String']['output']>;
  changeDate?: Maybe<Scalars['String']['output']>;
  orderHistoryId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tracking?: Maybe<Array<Maybe<SupportCaseOrderHistoryTracking>>>;
};

export type SupportCaseOrderHistoryTracking = {
  __typename?: 'SupportCaseOrderHistoryTracking';
  carrier?: Maybe<Scalars['String']['output']>;
  estimatedDeliveryDate?: Maybe<Scalars['String']['output']>;
  shipDescription?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  statusDate?: Maybe<Scalars['String']['output']>;
  statusText?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  trackingURL?: Maybe<Scalars['String']['output']>;
};

export type SupportContactOption = {
  __typename?: 'SupportContactOption';
  contactOptionsFull?: Maybe<Array<Maybe<SupportContactOptionsFull>>>;
  sequence?: Maybe<Scalars['String']['output']>;
};

export type SupportContactOptionsFull = {
  __typename?: 'SupportContactOptionsFull';
  btnFlag?: Maybe<Scalars['Int']['output']>;
  caseExpiration?: Maybe<Scalars['Int']['output']>;
  caseOptional?: Maybe<Scalars['Int']['output']>;
  chatQueue?: Maybe<Scalars['String']['output']>;
  cmd?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  countryCd?: Maybe<Scalars['String']['output']>;
  countryServiceDesc?: Maybe<Scalars['String']['output']>;
  crmChannel?: Maybe<Scalars['String']['output']>;
  en?: Maybe<Scalars['Int']['output']>;
  iconCd?: Maybe<Scalars['String']['output']>;
  internet?: Maybe<Scalars['Int']['output']>;
  openStatus?: Maybe<Scalars['String']['output']>;
  optionUrl?: Maybe<Scalars['String']['output']>;
  optionUrlDesc?: Maybe<Scalars['String']['output']>;
  phoneGroups?: Maybe<Array<Maybe<SupportPhoneGroups>>>;
  remainingOpenTime?: Maybe<Scalars['Int']['output']>;
  searchGroup?: Maybe<Scalars['String']['output']>;
  section?: Maybe<Scalars['Int']['output']>;
  serviceDesc?: Maybe<Scalars['String']['output']>;
  serviceDesc2?: Maybe<Scalars['String']['output']>;
  serviceDescClosed?: Maybe<Scalars['String']['output']>;
  serviceId?: Maybe<Scalars['String']['output']>;
  serviceName?: Maybe<Scalars['String']['output']>;
  serviceTitle?: Maybe<Scalars['String']['output']>;
  serviceType?: Maybe<Scalars['Int']['output']>;
  serviceUrl?: Maybe<Scalars['String']['output']>;
  signInRequired?: Maybe<Scalars['Int']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
  timeZoneOffSet?: Maybe<Scalars['String']['output']>;
  whDesc?: Maybe<Scalars['String']['output']>;
  whExceptions?: Maybe<Scalars['String']['output']>;
  whFee?: Maybe<Scalars['String']['output']>;
  whGroup?: Maybe<Scalars['String']['output']>;
};

export type SupportPhoneGroups = {
  __typename?: 'SupportPhoneGroups';
  phone?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
};

export type SupportResource = {
  __typename?: 'SupportResource';
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  isBot?: Maybe<Scalars['Boolean']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SupportWarranty = {
  __typename?: 'SupportWarranty';
  caption?: Maybe<Scalars['String']['output']>;
  countries?: Maybe<Scalars['String']['output']>;
  deliverables?: Maybe<Scalars['String']['output']>;
  entitlements?: Maybe<Array<Maybe<SupportWarrantyEntitlements>>>;
  hardwareCarePackEndDate?: Maybe<Scalars['String']['output']>;
  lastCheck?: Maybe<Scalars['String']['output']>;
  msgCodes?: Maybe<Scalars['String']['output']>;
  onSite?: Maybe<Scalars['Boolean']['output']>;
  serviceLevel?: Maybe<Scalars['String']['output']>;
  serviceType?: Maybe<Scalars['String']['output']>;
  softwareCarePackEndDate?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['Int']['output']>;
  statusDetail?: Maybe<Scalars['String']['output']>;
  tooltip?: Maybe<Scalars['String']['output']>;
  warrantyEndDate?: Maybe<Scalars['String']['output']>;
  warrantyStartDate?: Maybe<Scalars['String']['output']>;
  warrantyType?: Maybe<Scalars['Int']['output']>;
  warrantyTypeDescription?: Maybe<Scalars['String']['output']>;
};

export type SupportWarrantyEntitlements = {
  __typename?: 'SupportWarrantyEntitlements';
  caption?: Maybe<Scalars['String']['output']>;
  deliverables?: Maybe<Scalars['String']['output']>;
  serviceLevel?: Maybe<Scalars['String']['output']>;
  serviceType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['Int']['output']>;
  warrantyEndDate?: Maybe<Scalars['String']['output']>;
  warrantyStartDate?: Maybe<Scalars['String']['output']>;
  warrantyType?: Maybe<Scalars['Int']['output']>;
  warrantyTypeDescription?: Maybe<Scalars['String']['output']>;
};

export type ThirdPartySoftwareEntity = SubscriptionEntity & {
  __typename?: 'ThirdPartySoftwareEntity';
  fulfillments?: Maybe<Array<Maybe<Fulfillment>>>;
  product?: Maybe<Product>;
  resourceId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<EntityType>;
};

export type TwoCheckout = PaymentMethod & {
  __typename?: 'TwoCheckout';
  email?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PaymentMethodStatus>;
  type: PaymentMethodType;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneVerified?: Maybe<Scalars['Boolean']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
  roleCategory?: Maybe<Scalars['String']['output']>;
};

export type UserItems = {
  __typename?: 'UserItems';
  items?: Maybe<Array<Maybe<User>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** Type for Virtual Agent session */
export type VaSession = {
  __typename?: 'VASession';
  /** Error code for the Virtual Agent session */
  errorCode?: Maybe<Scalars['String']['output']>;
  /** Error message for the Virtual Agent session */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** URL for the Virtual Agent session */
  launchUrl?: Maybe<Scalars['String']['output']>;
};

export type VaSessionInput = {
  /** The client application bot name */
  botClient?: InputMaybe<Scalars['String']['input']>;
  /** The client application country code */
  cc?: InputMaybe<Scalars['String']['input']>;
  /** JSON object with the input for the Virtual Agent session */
  json?: InputMaybe<Scalars['JSON']['input']>;
  /** The client application language code */
  lc?: InputMaybe<Scalars['String']['input']>;
};
