import IClientOsLink from '../infra/apollo/IClientOsLink';

class DebugLink extends IClientOsLink {
  private _debugMethod;

  constructor(debugMethod = console.log) {
    super();
    this._debugMethod = debugMethod;
  }
  request(operation, forward) {
    this._debugMethod(`Starting request of type: ${operation.operationName}`, {
      operation
    });
    return forward(operation).map((data) => {
      this._debugMethod(
        `Ending request of type: ${operation.operationName}`,
        data
      );
      return data;
    });
  }
}

export default DebugLink;
