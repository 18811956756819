import { Stack } from '../enums';

const getGraphQLServerUrl = (stack: Stack, isShellPortal: boolean) => {
  if (isShellPortal) {
    return getGraphQLShellServerUrl();
  }
  return getGraphQLApigeeServerUrl(stack);
};

const getGraphQLShellServerUrl = () => {
  return '/api/aggregation/graphql';
};

const getGraphQLApigeeServerUrl = (stack: Stack) => {
  switch (stack) {
    case Stack.LOCAL:
      return 'http://localhost:8080/aggregation/graphql';
    case Stack.DEV:
      return 'https://dev-us1.api.ws-hp.com/portalsvc/aggregation/graphql';
    case Stack.PIE:
      return 'https://pie-us1.api.ws-hp.com/portalsvc/aggregation/graphql';
    case Stack.STAGE:
      return 'https://stage-us1.api.ws-hp.com/portalsvc/aggregation/graphql';
    default:
      return 'https://us1.api.ws-hp.com/portalsvc/aggregation/graphql';
  }
};

export { getGraphQLServerUrl };
