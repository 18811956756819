import { setContext } from '@apollo/client/link/context';

// This can't be extended from ApolloLink because it uses async method.
const AuthLink = (authTokenCallback, XApiKey) => {
  if (authTokenCallback === undefined)
    throw new Error('authTokenCallback is required');

  const authLink = setContext(async (_, { headers }) => {
    const token = await authTokenCallback();
    const additionalHeaders = {
      'x-api-key': XApiKey
    };
    if (token) {
      additionalHeaders['authorization'] = `Bearer ${token}`;
    }
    return {
      headers: {
        ...headers,
        ...additionalHeaders
      }
    };
  });
  return authLink;
};

export default AuthLink;
