export enum Platform {
  IOS = 'ios',
  MAC = 'mac',
  ANDROID = 'android',
  WINDOWS = 'windows',
  WEB = 'web'
}

export enum Stack {
  LOCAL = 'local',
  DEV = 'dev',
  PIE = 'pie',
  STAGE = 'stage',
  PROD = 'prod'
}
