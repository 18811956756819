import { ClientOsOperation } from '../../infra/apollo/types';

const findFieldInOperation = (
  fieldName: string,
  operation: ClientOsOperation
) => {
  return (
    operation.query.definitions[0].selectionSet.selections.filter(
      (selection) => selection.name.value === fieldName
    ).length > 0
  );
};

export default findFieldInOperation;
