import AbstractLocalDevicesResolver from '../shared/AbstractLocalDevicesResolver';
import handleAndResolveAdditionalLocalDevicesResolvers from '../shared/utils/handleAndResolveAdditionalLocalDevicesResolvers';
import { DeviceResolver } from '../types';
import WindowsMockedResolver from './__mocks__/WindowsMockedResolver';
import WindowsMockedResolver2 from './__mocks__/WindowsMockedResolver2';

export default async function WindowsChainnedLocalResolversHandler(
  rootValue,
  args,
  context,
  info,
  mock,
  additionalLocalDevicesResolvers: AbstractLocalDevicesResolver[]
): Promise<DeviceResolver[]> {
  const resolveParams = { rootValue, args, context, info };

  let firstResolver: AbstractLocalDevicesResolver;
  let resolverChain: AbstractLocalDevicesResolver;

  if (mock) {
    firstResolver = new WindowsMockedResolver();
    const windowsMockedResolver2 = new WindowsMockedResolver2();
    // Set the chain of responsibility precedence order
    resolverChain = firstResolver.setNext(windowsMockedResolver2);
  }

  return handleAndResolveAdditionalLocalDevicesResolvers({
    firstResolver,
    resolverChain,
    additionalLocalDevicesResolvers,
    resolveParams
  });
}
