import { ClientOsDocumentNode } from '../../infra/apollo/types';

// TODO: figure out a better way to do this
const removeFieldFromDocument = (
  document: ClientOsDocumentNode,
  fieldName: string
) => {
  document.definitions[0].selectionSet.selections.forEach((selection) => {
    if (fieldName && selection.name.value === fieldName) {
      const localSelection = JSON.parse(JSON.stringify(selection));
      localSelection.name.value = fieldName;
      // Remove the diference based on fieldName
      document.definitions[0].selectionSet.selections =
        document.definitions[0].selectionSet.selections.filter(
          (selection) => selection.name.value !== fieldName
        );
    }
  });

  const transformedDocument = {
    ...document,
    definitions: [...document.definitions]
  };

  return transformedDocument;
};

export default removeFieldFromDocument;
