/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloLink } from '@apollo/client';
import {
  NextLink,
  Operation,
  RequestHandler,
  FetchResult,
  GraphQLRequest,
  Observable,
  Observer
} from '@apollo/client/core';

/** The class is a start of decoupling the Apollo of our business logic.
 *  This makes the system more flexible and easier to maintain.  */
export default class IClientOsLink extends ApolloLink {
  constructor(_request?: RequestHandler) {
    super(_request);
  }

  static empty(): ApolloLink {
    return super.empty();
  }

  static from(links: (ApolloLink | RequestHandler)[]): ApolloLink {
    return super.from(links);
  }

  static split(
    test: (op: Operation) => boolean,
    left: ApolloLink | RequestHandler,
    right?: ApolloLink | RequestHandler
  ): ApolloLink {
    return super.split(test, left, right);
  }

  static execute(
    link: ApolloLink,
    operation: GraphQLRequest
  ): Observable<FetchResult> {
    return super.execute(link, operation);
  }

  static concat(
    first: ApolloLink | RequestHandler,
    second: ApolloLink | RequestHandler
  ): ApolloLink {
    return super.concat(first, second);
  }

  split(
    test: (op: Operation) => boolean,
    left: ApolloLink | RequestHandler,
    right?: ApolloLink | RequestHandler
  ): ApolloLink {
    return super.split(test, left, right);
  }

  concat(next: ApolloLink | RequestHandler): ApolloLink {
    return super.concat(next);
  }

  request(
    operation: Operation,
    forward?: NextLink
  ): Observable<FetchResult> | null {
    return super.request(operation, forward);
  }

  onError(error: any, observer?: Observer<FetchResult>): false | void {
    return super.onError(error, observer);
  }

  setOnError(fn: ApolloLink['onError']): this {
    return super.setOnError(fn);
  }
}
