import { CustomResolvers } from '../../types';

type ResolverKeyType = string;

const getLocalResolversKeysFromCustomResolvers = (
  customResolvers: CustomResolvers
): string[] => {
  const localResolversKeys: ResolverKeyType[] = [];

  if (!customResolvers) {
    return localResolversKeys;
  }

  Object.keys(customResolvers).forEach((operation) => {
    Object.keys(customResolvers[operation]).forEach((key: string) => {
      if (!key.startsWith('local') && !key.startsWith('_'))
        throw new Error(
          `Invalid local resolver key "${key}". It should start with "local" or "_" prefix.`
        );
      localResolversKeys.push(key);
    });
  });
  return localResolversKeys;
};

export default getLocalResolversKeysFromCustomResolvers;
