import { Resolvers } from '@apollo/client';
import { CustomResolvers } from '../../../../types';

const transformCustomResolversIntoApolloResolvers = (
  customResolvers: CustomResolvers
): Resolvers => {
  const resolvers = {};
  if (!customResolvers) return resolvers;

  Object.keys(customResolvers).forEach((typeName) => {
    resolvers[typeName] = {
      ...customResolvers[typeName]
    };
  });

  return resolvers;
};

export default transformCustomResolversIntoApolloResolvers;
