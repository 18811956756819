import { Platform } from '../../../../enums';
import MacChainnedLocalResolversHandler from '../../Mac';
import IOSChainnedLocalResolversHandler from '../../IOS';
import AndroidChainnedLocalResolversHandler from '../../Android';
import WindowsChainnedLocalResolversHandler from '../../Windows';
import WebChainnedLocalResolversHandler from '../../Web';

const getLocalDeviceResolverByPlatform = async (platform: Platform) => {
  if (platform === Platform.MAC) {
    return MacChainnedLocalResolversHandler;
  } else if (platform === Platform.IOS) {
    return IOSChainnedLocalResolversHandler;
  } else if (platform === Platform.ANDROID) {
    return AndroidChainnedLocalResolversHandler;
  } else if (platform === Platform.WINDOWS) {
    return WindowsChainnedLocalResolversHandler;
  } else {
    return WebChainnedLocalResolversHandler;
  }
};

export default getLocalDeviceResolverByPlatform;
