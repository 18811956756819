import { ApolloClient } from '@apollo/client/core';
import createApolloClientBaseConfig from './apolloClientConfig';
import { createResolvers } from '../../resolvers';
import { CreateGraphQLClientOptions } from '../../types';
import transformCustomResolversIntoApolloResolvers from './utils/resolvers/transformCustomResolversIntoApolloResolvers';

const createApolloClient = ({
  authTokenCallback,
  XApiKey,
  platform,
  stack,
  mock,
  isShellPortal,
  serverUrl,
  additionalLocalDevicesResolvers,
  customResolvers
}: CreateGraphQLClientOptions): ApolloClient<unknown> => {
  const apolloClientConfig = createApolloClientBaseConfig({
    stack,
    authTokenCallback,
    isShellPortal,
    XApiKey,
    serverUrl,
    customResolvers
  });

  return new ApolloClient({
    ...apolloClientConfig,
    resolvers: [
      createResolvers(platform, mock, additionalLocalDevicesResolvers),
      transformCustomResolversIntoApolloResolvers(customResolvers)
    ],
    defaultOptions: {
      query: {
        errorPolicy: 'all'
      }
    }
  });
};

export default createApolloClient;
