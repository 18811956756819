/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import { IGraphQLService } from '../services/GraphQLService/IGraphQLService';
import type { ApolloError, OperationVariables } from '@apollo/client';
import { GraphQLError } from 'graphql';

export type GraphQLProviderParams = {
  children: ReactNodeType;
  client: IGraphQLService;
};

export type ReactType = typeof React;

export type ReactNodeType = ReactNode;

export type QueryMessageType = {
  ID?: string;
  query?: string;
  variables?: OperationVariables;
  type?: string;
  queryResult?: any;
};

export interface ISubscriber {
  subscribe: ({ publisherId, eventName }, callback) => Promise<ISubscription>;
  unsubscribe: () => Promise<void>;
}

export interface ISubscription {
  unsubscribe: () => Promise<void>;
}

export interface ISubscription {
  unsubscribe: () => Promise<void>;
}

export interface IPublisher {
  publish: (
    eventName: string,
    publishData: { type: string; payload: any }
  ) => Promise<any>;
}

export enum WebViewTypeEnum {
  ReactNativeWebView = 'react-native-webview',
  JarvisWebView = 'jarvis-webview'
}

export interface IUseQueryResponse {
  data: any;
  loading: boolean;
  error: ApolloError | GraphQLError;
}

export enum MessageType {
  query = 'query',
  mutation = 'mutation'
}
