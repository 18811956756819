import ApolloClientGraphQLService from './services/GraphQLService/ApolloClientGraphQLService';
import { IGraphQLService } from './services/GraphQLService/IGraphQLService';
import { Platform, Stack } from './enums';
import createApolloClient from './infra/apollo/apolloClient';
import { gql, DocumentNode } from '@apollo/client/core';
import { CreateGraphQLClientOptions, CustomResolvers } from './types';
import AbstractLocalDevicesResolver from './resolvers/devices/shared/AbstractLocalDevicesResolver';
import { DeviceResolver } from './resolvers/devices/types';
import { DeviceResolverParamsType } from './resolvers/devices/shared/types';
import GraphQLNativeClient from './services/GraphQLService/GraphQLNativeClient/GraphQLNativeClient';

import {
  ClientFetchResult,
  ClientQueryResult,
  ClientMutationResult,
  ClientUseMutationResult,
  ClientUseQueryResult,
  ClientUseLazyQueryResult
} from './services/GraphQLService/types';

const createGraphQLClient = ({
  authTokenCallback,
  platform,
  XApiKey,
  stack,
  mock,
  isShellPortal,
  serverUrl,
  additionalLocalDevicesResolvers,
  customResolvers
}: CreateGraphQLClientOptions): IGraphQLService => {
  const apolloClient = createApolloClient({
    authTokenCallback,
    XApiKey,
    platform,
    stack,
    mock,
    isShellPortal,
    serverUrl,
    additionalLocalDevicesResolvers,
    customResolvers
  });
  const client = new ApolloClientGraphQLService(apolloClient);

  return client;
};

type gqlType = typeof gql;

export {
  createGraphQLClient,
  gql,
  Platform,
  Stack,
  AbstractLocalDevicesResolver,
  GraphQLNativeClient
};
export type {
  gqlType,
  ClientQueryResult,
  ClientUseQueryResult,
  ClientFetchResult,
  ClientMutationResult,
  ClientUseMutationResult,
  ClientUseLazyQueryResult,
  CreateGraphQLClientOptions,
  IGraphQLService as GraphQLService,
  DocumentNode,
  DeviceResolver,
  DeviceResolverParamsType,
  CustomResolvers
};
