import AbstractLocalDevicesResolver from '../AbstractLocalDevicesResolver';
import { DeviceResolverParamsType } from '../types';

type HandleAndResolveAdditionalLocalDevicesResolversType = {
  firstResolver?: AbstractLocalDevicesResolver;
  resolverChain?: AbstractLocalDevicesResolver;
  additionalLocalDevicesResolvers?: AbstractLocalDevicesResolver[];
  resolveParams?: DeviceResolverParamsType;
};

const handleAndResolveAdditionalLocalDevicesResolvers = ({
  firstResolver,
  resolverChain,
  additionalLocalDevicesResolvers,
  resolveParams
}: HandleAndResolveAdditionalLocalDevicesResolversType) => {
  if (additionalLocalDevicesResolvers?.length)
    additionalLocalDevicesResolvers.forEach((resolver) => {
      if (!firstResolver) {
        firstResolver = resolver;
      } else if (resolverChain) {
        resolverChain.setNext(resolver);
      }
      resolverChain = resolver;
    });

  return firstResolver?.resolve(resolveParams) || [];
};

export default handleAndResolveAdditionalLocalDevicesResolvers;
