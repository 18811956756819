import { LOCAL_FIELDS } from '../constants';
import { Platform } from '../enums';
import devicesLocalResolver from './devices';
import AbstractLocalDevicesResolver from './devices/shared/AbstractLocalDevicesResolver';

/** This method sets the deviceLocalResolver to field DeviceLocal. */
const createResolvers = (
  platform: Platform,
  mock: boolean,
  additionalLocalDevicesResolvers: AbstractLocalDevicesResolver[]
) => {
  return {
    Query: {
      [LOCAL_FIELDS.devices]: async (rootValue, args, context, info) =>
        devicesLocalResolver({
          rootValue,
          args,
          context,
          info,
          mock,
          platform,
          additionalLocalDevicesResolvers
        })
    }
  };
};

export { createResolvers };
