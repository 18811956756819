import { DocumentNode, DocumentTransform } from '@apollo/client/core';
import { LOCAL_RESOLVERS_SET_NAMES } from '../../constants';

/** This is a clousre for transformDocument */
export const createApolloDocumentTransform = (
  customResolversKeys: string[]
): DocumentTransform => {
  return new DocumentTransform((document) =>
    transformDocument(document, customResolversKeys)
  );
};

// This method changes either the parameter and the returned data,
// so, it should be checked in the future.
export const transformDocument = (
  document: DocumentNode,
  customResolversKeys: string[]
) => {
  const localSelections = [];

  // TODO: Need to create a recursive method to find all local fields
  // Create a recursive method to find all the localFields in document that is listed as one of the LOCAL_QUERY_OBJECTS, clone them and add them to the document
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (document.definitions[0] as any).selectionSet.selections.forEach(
    (selection) => {
      if (LOCAL_RESOLVERS_SET_NAMES.includes(selection.name.value)) {
        const localSetName = `${selection.name.value}Local`;

        // TODO: Get a better clone way
        const localSelection = JSON.parse(JSON.stringify(selection));
        localSelection.name.value = localSetName;

        // TODO: This is a way to add the @client directive, need to move this to a proper place.
        // With the @client directive, the Apollo removes the query before sending to links and the query will be resolved locally.
        localSelection.directives = [
          ...localSelection.directives,
          {
            arguments: [],
            kind: 'Directive',
            name: { kind: 'Name', value: 'client' }
          }
        ];

        localSelections.push(localSelection);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (document.definitions[0] as any).selectionSet.selections.push(
          ...localSelections
        );
      } else if (customResolversKeys.includes(selection.name.value)) {
        selection.directives = [
          ...selection.directives,
          {
            arguments: [],
            kind: 'Directive',
            name: { kind: 'Name', value: 'client' }
          }
        ];
      }
    }
  );

  const transformedDocument = {
    ...document,
    definitions: [...document.definitions]
  };

  return transformedDocument;
};
