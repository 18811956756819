/* eslint-disable @typescript-eslint/no-explicit-any */
import { getQueryRequestService } from 'src/services/QueryRequestService';
import { IGraphQLService } from '../../IGraphQLService';
import { ClientFetchResult, ClientQueryResult } from '../../types';
import { WebviewSharedGraphQLMethodsDependencies } from './types';
import { GraphQLError } from 'graphql';

export const createGraphQLMethodsForWindowsWebview = (
  _options: WebviewSharedGraphQLMethodsDependencies
): IGraphQLService => {
  console.log('Initialize GraphQLMethods For WindowsWebview');
  return {
    query: async <TData, TVariables>(
      query: string,
      variables?: TVariables
    ): Promise<ClientQueryResult<TData>> => {
      const requestService = getQueryRequestService();
      return new Promise((resolve) => {
        requestService.register(
          {
            type: 'query',
            query,
            variables
          },
          (ev) => {
            resolve({
              data: ev.data as TData,
              errors: ev.errors as GraphQLError[],
              loading: false
            });
          },
          { discardCallback: true }
        );
      });
    },
    mutate: async <TData, TVariables>(
      mutation: string,
      variables?: TVariables
    ): Promise<ClientFetchResult<TData | undefined>> => {
      const requestService = getQueryRequestService();
      return new Promise((resolve) => {
        requestService.send(
          {
            type: 'mutation',
            query: mutation,
            variables
          },
          (ev) => {
            resolve({
              data: ev.data as TData,
              errors: ev.errors as GraphQLError[]
            });
          }
        );
      });
    }
  };
};
