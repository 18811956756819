import { DeviceResolver } from '../types';
import AndroidMockedResolver from './__mocks__/AndroidMockedResolver';
import AbstractLocalDevicesResolver from '../shared/AbstractLocalDevicesResolver';
import handleAndResolveAdditionalLocalDevicesResolvers from '../shared/utils/handleAndResolveAdditionalLocalDevicesResolvers';

export default async function AndroidChainnedLocalResolversHandler(
  rootValue,
  args,
  context,
  info,
  mock,
  additionalLocalDevicesResolvers: AbstractLocalDevicesResolver[]
): Promise<DeviceResolver[]> {
  const resolveParams = { rootValue, args, context, info };

  if (mock) {
    const mockedResolver = new AndroidMockedResolver();
    return mockedResolver.resolve(resolveParams);
  }

  return handleAndResolveAdditionalLocalDevicesResolvers({
    firstResolver: undefined,
    resolverChain: undefined,
    additionalLocalDevicesResolvers,
    resolveParams
  });
}
