import { DeviceItems } from '../../generated/graphql';
import { DeviceResolver } from './types';
import getLocalDeviceResolverByPlatform from './shared/utils/getLocalDeviceResolverByPlatform';
import { BaseResolverParamsType } from '../shared/types';
import { Platform } from '../../enums';
import AbstractLocalDevicesResolver from './shared/AbstractLocalDevicesResolver';
import mergeObjects from './shared/utils/mergeObjects';
import findCorrespondingDevice from './shared/utils/findCorrespondingDevice';

/**
 * This function is intended to resolve devices locally based on the provided platform.
 */
type DevicesLocalResolverParams = BaseResolverParamsType & {
  mock?: boolean;
  platform: Platform;
  additionalLocalDevicesResolvers?: AbstractLocalDevicesResolver[];
};

const devicesLocalResolver = async ({
  rootValue,
  args,
  context,
  info,
  mock,
  platform,
  additionalLocalDevicesResolvers
}: DevicesLocalResolverParams): Promise<DeviceItems> => {
  // 1. Retrieve the device resolver specific to the provided platform.
  const _deviceLocalResolver = await getLocalDeviceResolverByPlatform(platform);
  // 2. Get the local devices.
  const localDevices: DeviceResolver[] = await _deviceLocalResolver(
    rootValue,
    args,
    context,
    info,
    mock,
    additionalLocalDevicesResolvers
  );

  // 3. The response object.
  const response: DeviceItems = {
    items: localDevices,
    __typename: 'DeviceItems',
    totalCount: localDevices.length
  };

  // TODO: From here until the end should be improved
  if (!rootValue?.devices) {
    rootValue.devices = { items: [], __typename: 'DeviceItems', totalCount: 0 };
  }

  const cloudDevices = rootValue?.devices?.items || [];

  let totalMergedDevices = 0;
  localDevices.forEach((localDevice) => {
    const correspondingCloudDevice = findCorrespondingDevice(
      localDevice,
      cloudDevices
    );
    if (correspondingCloudDevice) {
      totalMergedDevices++;
      // Merge data that doesn't exist on params.data
      mergeObjects(
        localDevice,
        correspondingCloudDevice,
        correspondingCloudDevice
      );
    } else {
      // Create a new object inside params.data
      cloudDevices.unshift(localDevice);
    }
  });

  if (!isNaN(rootValue?.devices?.totalCount)) {
    rootValue.devices.totalCount =
      rootValue.devices.totalCount + localDevices.length - totalMergedDevices;
  }

  if (args?.deviceId) {
    const filteredDevice = cloudDevices.find(
      (d) => d.deviceId === args.deviceId
    );
    cloudDevices.length = 0;
    if (filteredDevice) {
      cloudDevices.push(filteredDevice);
      rootValue.devices.totalCount = 1;
      response.items = [filteredDevice];
      response.totalCount = 1;
    } else {
      rootValue.devices.totalCount = 0;
      response.items = [];
      response.totalCount = 0;
    }
  }

  return response;
};

export default devicesLocalResolver;
