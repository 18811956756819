/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * This method merges two objects, the strongData and the weakData. The strongData has the priority over the weakData.
 * All of the data will be merged into the updatedData. The updated data could be an empty object or the same reference of the strongData or weakData.
 */

function isObject(obj: any): boolean {
  return obj !== null && typeof obj === 'object';
}

const mergeObjects = (
  strongData: Record<string, any>,
  weakData: Record<string, any>,
  updateData: Record<string, any>
) => {
  const strongDataKeys = strongData ? Object.keys(strongData) : [];
  const weakDataKeys = weakData ? Object.keys(weakData) : [];
  const mergedKeys = Array.from(new Set([...strongDataKeys, ...weakDataKeys]));

  if (!strongData) {
    Object.assign(updateData, weakData);
    return;
  }

  mergedKeys.forEach((key) => {
    if (key in strongData) {
      // TODO: Test with array values
      if (isObject(updateData[key])) {
        if (isObject(strongData[key])) {
          mergeObjects(strongData[key], weakData[key], updateData[key]);
        } else if (isObject(weakData[key])) {
          mergeObjects(strongData[key], weakData[key], updateData[key]);
        } else {
          updateData[key] = weakData[key];
        }
      } else {
        updateData[key] = strongData[key];
        if (weakData && isObject(weakData[key])) {
          Object.assign(updateData[key], weakData[key]);
        }
      }
    } else {
      updateData[key] = weakData[key];
    }
  });
};

export default mergeObjects;
