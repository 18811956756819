import hash from 'string-hash';
import {
  QueryCallback,
  Publisher,
  QueryRequest,
  InboundEventMessage,
  QueryResponse,
  ReactNativeWebViewInboundMessage,
  JarvisWebviewInboundMessage
} from './types';
import { WebViewTypeEnum } from '../../react/types';

const parseEvent = (ev: InboundEventMessage) => {
  const response =
    (ev as ReactNativeWebViewInboundMessage).data?.response ||
    (ev as JarvisWebviewInboundMessage).eventData?.response;
  return {
    ID:
      (ev as ReactNativeWebViewInboundMessage).data?.ID ||
      (ev as JarvisWebviewInboundMessage).eventData?.ID,
    response: { ...response, eventName: ev.eventName }
  };
};

const extractQueryKeys = (queryString: string) => {
  const regex = /\b(\w*|_\w+)\b/g;
  const matches = queryString.match(regex);
  return matches || [];
};

export const getID = (queryData: QueryRequest) =>
  hash(JSON.stringify(queryData).replace(/\s+/g, '')).toString(16);

export class QueryRequestService {
  private publisher: Publisher | null;

  queryRequests = new Map<
    string,
    QueryRequest & { status: 'pending' | 'sent' | 'responded'; keys: string[] }
  >();
  queryCallbacks = new Map<string, QueryCallback[]>();
  queryResponses = new Map<string, QueryResponse>();

  constructor() {
    (async () => {
      try {
        /* eslint-disable-next-line */
        const EventService = (window as any).JWeb.Plugins.EventService;
        const subscriber = await EventService?.createSubscriber();
        await subscriber.subscribe(
          { eventName: 'graphqlResult', publisherId: 'root-webview2' },
          (ev) => this.processEvent(ev)
        );
        this.publisher = await EventService?.createPublisher('graphql-hooks');
        this.processPendingRequests();
      } catch (err) {
        console.error('Error initializing QueryRequestService:', err);
      }
    })();
  }

  private sendMessage(messageData: QueryRequest & { ID: string }) {
    /* eslint-disable */
    const webviewType = (window as any).Shell.manifest.services.graphql
      .webviewType;
    if (webviewType === WebViewTypeEnum.ReactNativeWebView) {
      (window as any).ReactNativeWebView?.postMessage(
        JSON.stringify(messageData)
      );
    }
    /* eslint-enable */
    this.publisher?.publish('graphql', {
      type: messageData.type,
      payload: messageData
    });
  }

  private processPendingRequests() {
    this.queryRequests.forEach((queryRequest, queryID) => {
      if (queryRequest.status === 'pending') {
        this.queryRequests.set(queryID, { ...queryRequest, status: 'sent' });
        this.sendMessage({ ...queryRequest, ID: queryID });
      }
    });
  }

  private registerQueryRequest(queryID: string, queryData: QueryRequest) {
    if (!this.queryRequests.has(queryID)) {
      this.queryRequests.set(queryID, {
        ...queryData,
        status: 'pending',
        keys: extractQueryKeys(queryData.query)
      });
    }

    if (this.publisher) {
      this.processPendingRequests();
    }
    // If we don't have a publisher yet, it will flush queries when it's ready.
  }

  refetchQueries(event: JarvisWebviewInboundMessage) {
    const queriesToRefetch = Array.from(this.queryRequests.keys()).filter(
      (queryID) => {
        const queryData = this.queryRequests.get(queryID);
        for (const change of event.eventData.changes) {
          if (queryData.keys.includes(change)) {
            return true;
          }
        }
        return false;
      }
    );

    for (const queryID of queriesToRefetch) {
      const queryRequest = this.queryRequests.get(queryID);
      this.queryRequests.set(queryID, { ...queryRequest, status: 'pending' });
      this.queryResponses.delete(queryID);
    }

    this.processPendingRequests();
  }

  processEvent(event: InboundEventMessage) {
    if (
      (event as JarvisWebviewInboundMessage).eventData?.type ===
      'refetchQueries'
    ) {
      return this.refetchQueries(event);
    }
    this.notify(event);
  }

  notify(event: InboundEventMessage) {
    const { ID, response } = parseEvent(event);
    const callbacks = this.queryCallbacks.get(ID);
    if (!callbacks) {
      return;
    }

    for (const callback of callbacks) {
      callback(response);
    }

    if (this.queryRequests.has(ID)) {
      this.queryResponses.set(ID, response);
      this.queryRequests.set(ID, {
        ...this.queryRequests.get(ID),
        status: 'responded'
      });
    }
  }

  register(
    queryData: QueryRequest,
    callback: QueryCallback,
    options = { discardCallback: false }
  ) {
    const queryID = getID(queryData);

    if (options.discardCallback) {
      const cb = callback;
      callback = (response) => {
        cb(response);
        this.unregister(queryID, callback);
      };
    }

    let callbacks = this.queryCallbacks.get(queryID);
    if (!callbacks) {
      callbacks = [];
    }
    this.queryCallbacks.set(queryID, [...callbacks, callback]);

    const response = this.queryResponses.get(queryID);
    if (response) {
      callback(response);
    } else {
      this.registerQueryRequest(queryID, queryData);
    }

    return queryID;
  }

  send(queryData: QueryRequest, callback: QueryCallback) {
    const queryID = getID(queryData);

    let callbacks = this.queryCallbacks.get(queryID);
    if (!callbacks) {
      callbacks = [];
    }
    this.sendMessage({ ...queryData, ID: queryID });
    const onceCallback: QueryCallback = (response) => {
      this.unregister(queryID, onceCallback);
      callback(response);
    };
    this.queryCallbacks.set(queryID, [...callbacks, onceCallback]);
    return queryID;
  }

  unregister(queryID: string, callback: QueryCallback) {
    const callbacks = this.queryCallbacks.get(queryID);
    if (!callbacks) {
      return;
    }

    this.queryCallbacks.set(
      queryID,
      callbacks.filter((cb) => cb !== callback)
    );
  }
}

let queryRequestService: QueryRequestService;

export function getQueryRequestService() {
  if (!queryRequestService) {
    queryRequestService = new QueryRequestService();
  }
  return queryRequestService;
}
