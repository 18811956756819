import { Observable } from '@apollo/client';
import IClientOsLink from '../infra/apollo/IClientOsLink';

/**
 * This link is used to prevent the request from being sent to the server.
 *  Acting as a Endpoint Link, it make every GraphQL request return
 * { data: { data: null } }, regardless of the actual query or mutation and
 * not calling the GraphQL Cloud Server. */
class DeadendLink extends IClientOsLink {
  constructor() {
    super();
  }
  request() {
    const result = { data: { data: null } };
    const observable = Observable.of(result);
    return observable;
  }
}

export default DeadendLink;
