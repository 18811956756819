import { NativeAppleAndroidGraphQLService } from 'src/react/NativeAppleAndroidGraphQLReactTools/types';
import { IGraphQLService } from '../../IGraphQLService';
import { ClientFetchResult, ClientQueryResult } from '../../types';
import { WebviewSharedGraphQLMethodsDependencies } from './types';
import { throwMissingMfeGraphqlServiceError } from 'src/react/NativeAppleAndroidGraphQLReactTools/helpers/throwMissingMfeGraphqlServiceError';
import { getNativeAppleAndroidApolloClientBody } from 'src/react/NativeAppleAndroidGraphQLReactTools/helpers/getNativeAppleAndroidApolloClientBody';
import { gql } from '@apollo/client';

export const createGraphQLMethodsForIOSWebview = (options: {
  dependencies: WebviewSharedGraphQLMethodsDependencies;
}): IGraphQLService => ({
  query: async <TData, TVariables>(
    query: string,
    variables?: TVariables
  ): Promise<ClientQueryResult<TData>> => {
    let mfeApolloClient: NativeAppleAndroidGraphQLService<TData>;

    try {
      mfeApolloClient = options.dependencies.JWeb.Plugins.MfeGraphqlService;

      if (!mfeApolloClient) {
        throwMissingMfeGraphqlServiceError('query');
      }
    } catch (error) {
      throwMissingMfeGraphqlServiceError('query');
    }

    const body = getNativeAppleAndroidApolloClientBody(
      gql(query),
      {
        variables
      },
      true
    );

    return mfeApolloClient
      .query(body)
      .then((res) => {
        return {
          ...res,
          loading: false
        };
      })
      .catch((error) => {
        return {
          data: undefined,
          loading: false,
          error
        };
      });
  },

  mutate: async <TData, TVariables>(
    mutation: string,
    variables?: TVariables
  ): Promise<ClientFetchResult<TData | undefined>> => {
    let mfeApolloClient: NativeAppleAndroidGraphQLService<TData>;

    try {
      mfeApolloClient = options.dependencies.JWeb.Plugins.MfeGraphqlService;

      if (!mfeApolloClient) {
        throwMissingMfeGraphqlServiceError('mutate');
      }
    } catch (error) {
      throwMissingMfeGraphqlServiceError('mutate');
    }

    const body = getNativeAppleAndroidApolloClientBody(gql(mutation), {
      variables
    });

    return mfeApolloClient
      .mutate(body)
      .then((res) => {
        return {
          ...res,
          loading: false
        };
      })
      .catch((error) => {
        return {
          data: undefined,
          loading: false,
          error
        };
      });
  }
});
