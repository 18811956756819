import { Device } from 'src/generated/graphql';

export default function findCorrespondingDevice(
  device: Device,
  deviceList: Device[]
) {
  const productNumber = device.identity?.makeAndModel?.number;
  const serialNumber = device.identity?.serialNumber;
  const deviceId = device.deviceId;

  let matchingDevice = null;

  if (productNumber) {
    matchingDevice = deviceList.find(
      (d) =>
        !!d.identity?.makeAndModel?.number &&
        d.identity.makeAndModel.number === productNumber
    );
  }

  if (serialNumber && !matchingDevice) {
    matchingDevice = deviceList.find(
      (d) =>
        !!d.identity?.serialNumber && d.identity.serialNumber === serialNumber
    );
  }

  if (!matchingDevice) {
    matchingDevice = deviceList.find(
      (d) => !!d.deviceId && d.deviceId === deviceId
    );
  }

  return matchingDevice;
}
