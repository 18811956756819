/* eslint-disable @typescript-eslint/no-explicit-any */
import { IGraphQLService as GraphQLService } from '../IGraphQLService';
import { ClientFetchResult, ClientQueryResult } from '../types';
import { Platform } from '../../../enums';
import {
  createGraphQLMethodsForWindowsWebview,
  createGraphQLMethodsForAndroidWebview,
  createGraphQLMethodsForIOSWebview
} from './platform';
import { WebviewSharedGraphQLMethodsDependencies } from './platform/types';

const graphQLMethodsForWebView = {
  [Platform.WINDOWS]: createGraphQLMethodsForWindowsWebview,
  [Platform.ANDROID]: createGraphQLMethodsForAndroidWebview,
  [Platform.IOS]: createGraphQLMethodsForIOSWebview
};

class GraphQLNativeClient implements GraphQLService {
  private webviewGraphQLMethods: GraphQLService;

  constructor(
    platform: Platform,
    options: { dependencies: WebviewSharedGraphQLMethodsDependencies }
  ) {
    this.webviewGraphQLMethods = graphQLMethodsForWebView[platform](options);
  }

  public async query<TData = any, TVariables = any>(
    query: string,
    variables?: TVariables
  ): Promise<ClientQueryResult<TData>> {
    return this.webviewGraphQLMethods.query<TData, TVariables>(
      query,
      variables
    );
  }

  public async mutate<TData = any, TVariables = any>(
    mutation: string,
    variables?: TVariables
  ): Promise<ClientFetchResult<TData>> {
    return this.webviewGraphQLMethods.mutate<TData, TVariables>(
      mutation,
      variables
    );
  }
}

export default GraphQLNativeClient;
